<template>
  <div>
    <apexchart
      ref="lineChart"
      type="line"
      :options="chartOptions"
      :series="chartSeries"
    ></apexchart>
  </div>
</template>

<script>
// import VueApexCharts from "vue3-apexcharts";

export default {
  name: "ArrowChart",
  props: {
    oilData: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    chartSeries() {
      // this.updateChart();
    },
  },
  created() {
    // this.chartSeries = [];
    // this.chartSeries.push({});
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "line",
          animations: {
            enabled: true,
          },
        },
        annotations: {
          points: [
            {
              x: 5,
              y: 5,
              // label: {
              //   text: "Lowest: 322",
              //   offsetY: 2,
              // },
              image: {
                path:
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/Flat_tick_icon.svg/512px-Flat_tick_icon.svg.png",
                width: undefined,
                height: undefined,
              },
            },
            {
              x: 100,
              y: 10,
              image: {
                path:
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/Flat_tick_icon.svg/512px-Flat_tick_icon.svg.png",
                width: undefined,
                height: undefined,
              },
            },
            {
              x: 200,
              y: 15,
              image: {
                path:
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/Flat_tick_icon.svg/512px-Flat_tick_icon.svg.png",
                width: undefined,
                height: undefined,
              },
            },
          ],
        },
        xaxis: {
          categories: [
            "00:00",
            "03:00",
            "06:00",
            "09:00",
            "12:00",
            "15:00",
            "18:00",
            "21:00",
            "00:00",
          ],
        },
        yaxis: {
          title: {
            text: "Speed (km/h)",
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      chartSeries: [
        {
          name: "Speed",
          data: [5, 10, 15, 20, 30, 50, 40, 30, 25],
        },
      ],
    };
  },
  methods: {
    updateChart() {
      console.log("update chart");
      this.$refs.lineChart.updateOptions({});
    },
  },
};
</script>
<style scoped>
/* Optional: Add styles for better appearance */
</style>
